<template>
<div>
  <template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" v-model="open" max-width="408px" persistent>
        <v-card :loading="loading" width="408px" >
          <div class="tw-p-6 ">
            <div class=" tw-flex tw-mb-3 tw-mt-7 tw-justify-between">
              <div class="Title pb-2">
                <div>Make Recommendation</div>
              </div>
              <div class="tw-cursor-pointer" @click="$emit('close')">
                <vue-feather type="x" style="width:19px"/>
              </div>
            </div>

            <div>
              <div class="cad tw-mb-8" v-for="(option, i) in options" :key="i" @click="showSummary(option.summary)">
                <v-radio-group v-model="radios" column>
                  <v-radio :value="option.summary">
                    <template v-slot:label>
                      <div class='txt tw-ml-3'>{{option.summary}}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="tw-mt-8">
              <h2>Comment</h2>
              <v-textarea
              id="message"
              hide-details
              solo
              :rules="rules"
              v-model="message"
              placeholder="Ex.She is cool"
              no-resize/>
            </div>

            <div class="tw-mb-5 tw-mt-9 tw-mx-2 md:tw-flex md:tw-justify-end">
              <BaseButton id="cancelBtn" outlined style="border-radius: 8px !important;" :block="true" button-text="Cancel" @click="$emit('close')"/>
              <BaseButton id="submitBtn" :loading="loading" :disabled="!message" class="md:tw-ml-4 tw-mt-3 md:tw-mt-0  md:tw-w-36" style="border-radius: 8px !important;"
                          depressed :block="true" height="48px" button-text="Submit" @click="goto"/>
            </div>
          </div>
        </v-card>
    </v-dialog>
  </template>
</div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {endInterview} from "@/services/api/APIService";
import {mapGetters} from "vuex";
export default {
  name: "RecommendationModal",
  components: {BaseButton},
  props:{
    open: [Boolean],
  },
  data(){
    return{
      loading: false,
      disabled: false,
      message: '',
      radios: false,
      summary:'',
      rules: [v => !!v || 'Field is required'],
      options:[
        { id:1,
         summary:"I am not sure"},
        { id: 2,
          summary:"Offer Admission"},
        { id: 3,
          summary:"Send to Talent Pool"},
        { id: 4,
          summary:"Blacklist"}
      ]
    }
  },
  computed:{
    ...mapGetters('interview',['getInterviewId','getSummary']),
    showOption(){
      let val
      if(this.radios === 'I am not sure'){
       val = 'i_am_not_sure'
      }else if(this.radios === 'Offer Admission'){
        val= 'offer_admission'
      }else if(this.radios === 'Send to Talent Pool'){
        val ='send_to_community'
      }else if(this.radios === 'Blacklist'){
        val= 'blacklist'
      }
      return val
    }
  },


  methods:{
    showSummary(val){
      this.summary = val
      console.log(this.summary)
    },
    goto(){
      this.loading =true
      let data={
        site_name:this.$route.query.site_name,
        interview: +this.$route.query.interview_id,
        candidate:this.$route.query.email,
        message:this.message,
        summary: this.showOption
      }
      endInterview(data)
      .then(res=>{
        console.log(res)
        this.$displaySnackbar({
          message:'successful',
          success:true
        })
        this.$emit('close')
        this.$router.push({query:{...this.$route.query, is_recommend:'true'}})
      })
      .catch(err=>{
        console.log(err)
      })
      .finally(()=>this.loading=false)

      // this.$router.push({path:'/candidateProfile', query:{name: this.$route.query.name,program_title:this.$route.query.program_title,status:this.$route.query.status,applicant_id: this.$route.query.applicant_id,site_name:this.$route.query.site_name,email:this.$route.query.email, program_id:this.$route.query.program_id,from:this.$route.query.from,to:this.$route.query.to}})
    }
  }
}
</script>

<style scoped lang="scss">
.Title{
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.cad{
  background: #FFFFFF;
  border: 1px solid #9CABB5;
  border-radius: 8px;
}
.txt{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.v-input--selection-controls {
  margin-top: 16px;
  margin-left: 14px;
  padding-top: 4px;
}
h2{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>