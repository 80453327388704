<template>
  <div>
  <Header>
    <template #right-header-component>
      <ProfileDropDown/>
    </template>
  </Header>
  <div class="tw-pt-24 tw-mx-20">
    <Loader v-if="getLoading"/>
    <div v-else>
    <div>
      <div class="tw-flex tw-cursor-pointer tw-pb-3 tw-pt-9" @click="goto">
        <v-icon class="tw-mr-1.5" dense>mdi-chevron-left</v-icon>
        <span class="dash-text " style="color: #001343">Back</span>
      </div>
      <div class="d-flex justify-space-between">
        <div>
          <h4 class="title">Interview with {{this.$route.query.name}}</h4>
          <span class="sub-title tw-pb-5">{{ this.$route.query.program_title }} - APPLICANT</span>
        </div>
        <div class="tw-flex" v-if="!applyStage.includes($route.query.status)">
          <!--          <div class="tw-flex tw-items-center tw-mr-4">-->
          <!--            <vue-feather type="clock" style="color: #008EEF" class="tw-mr-1 mb-n1"/>-->
          <!--            <h5 class="timer tw-ml-1">00:25</h5>-->
          <!--          </div>-->
          <div class="tw-ml-4" v-if="!this.$route.query.is_recommend" >
            <BaseButton button-text="End Interview" class="mt-n1" height="48px" width="153px" @click="dialog=true"/>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-row no-gutters dense>
        <v-col cols="12" lg="8" md="7">
          <div v-if="this.$route.query.is_recommend === 'true'">
            <RecommendationComment/>
          </div>
          <div v-else>

          <div class=" card1 tw-flex tw-pt-4 tw-mb-4 lg:tw-mt-0">
            <div>
              <h2>{{ this.$route.query.psychometric_score }}%</h2>
              <span>Aptitude</span>
            </div>
<!--            <div class="tw-ml-6">-->
<!--              <h2>{{ this.$route.query.personality_score }}%</h2>-->
<!--              <span>Personality</span>-->
<!--            </div>-->
          </div>
          <div>
            <div v-if="getInterviewQuestion && getInterviewQuestion.length" class="scroll">
              <h3 class="">Questions</h3>
              <ol class="list">
                <li v-for="(question) in getInterviewQuestion" :key="question.id" v-html="question.content">  </li>
              </ol>
            </div>
            <div v-else class="card2">
              <div class="mx-auto justify-center align-center tw-flex tw-pt-32">
                <h4>You haven’t set the <strong>Interview</strong> <br><strong> Questions</strong> for this interview
                </h4>
              </div>
              <div class="btn tw-mt-10 tw-flex mx-auto justify-center align-center">
                <v-btn class="text-capitalize white--text" color="#1E323F" @click="setQuestion">Set Interview Questions</v-btn>
              </div>
            </div>
          </div>
          </div>
        </v-col>
        <v-col cols="12" lg="1" md="1"></v-col>
        <v-col cols="12" lg="3" md="4">
          <CommentCard/>
        </v-col>
      </v-row>
    </div>
    </div>
    </div>
<!--    <component :is="showRecommendationView()"/>-->
    <EndProgramInterviewModal :dialog="dialog" @close="dialog=false"/>
<!--    -->
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import CommentCard from "@/components/CommentCard";
import {mapGetters} from "vuex";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import Loader from "@/components/UIComponents/reusablesIcon/Loader";
import RecommendationComment from "@/components/RecommendationComment";
import EndProgramInterviewModal from "@/components/EndProgramInterviewModal";


export default {
  name: "InterviewMainCard",
  components: {RecommendationComment, EndProgramInterviewModal, Loader, ProfileDropDown, Header, CommentCard, BaseButton},
  data() {
    return {
      applyStage:['offered_employment','interviewed','Hired','Declined','Rejected','offered_admission'],
      // interviewQuestion: [
      //   {
      //     id: 1,
      //     content: 'What is life?'
      //   },
      //   {
      //     id: 2,
      //     content: 'How is growing up like?'
      //   },
      //   {
      //     id: 1,
      //     content: 'How does the blue color representation in the sky?'
      //   }
      // ],

      dialog:false
    }
  },
  computed: {
    ...mapGetters('interview', ["getInterviewQuestion","getLoading"])
  },
  created() {
    const payload = {
      site_name: this.$route.query.site_name,
      program_id: this.$route.query.program_id
    }
    this.$store.dispatch('interview/getInterviewQuestion', payload)
  },

  methods:{

    goto(){
      this.$router.push({path:'/candidateProfile', query:{...this.$route.query}})
    },
    setQuestion(){
      // id:"82"
      // programTitle:"Worst That Can Happen"
      // view:"applicants"
      let token = sessionStorage.getItem('userToken')

      window.location.href = `${process.env.VUE_APP_ORGANIZATION_URL}/redirect?token=${token}&to=admin-interview&id=${this.$route.query.program_id}&programTitle=${this.$route.query.program_title}&view=applicants&status=${this.$route.query.status}&applicant_id=${this.$route.query.applicant_id}&site_name=${this.$route.query.site_name}&email=${this.$route.query.email}&program_id=${this.$route.query.program_id}&program_title=${this.$route.query.program_title}&from=${this.$route.name}&personality_score=${this.$route.query.personality_score}&psychometric_score=${this.$route.query.psychometric_score}&name=${this.$route.query.name}&interview_id=${this.$route.query.interview_id}`
    }

  }


}
</script>

<style scoped>
.dash-text {
  ont-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.title {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}

.list {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 50px;
  color: #001343;
}

.sub-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #001343;
}

.timer {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #008EEF;
}

.card1 {
  /*flex-direction: row;*/
  /*align-items: center;*/
  padding: 24px;
  /*width: 804px;*/
  height: 106px;
  /*left: 126px;*/
  /*top: 213px;*/
  background: #FAFAFA;
  border-radius: 8px;
}

h2 {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #000000;
}

span {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2B1C1C
}

.card2 {
  min-height: 399px;
  background: #F7FCFF;
  border-radius: 12px;
  /*width: 804px;*/
}

.scroll {

  /*width: 802px;*/
  /*height: 407px;*/
  /*top: 345px;*/
  padding: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  position: static;
  width: 232px;
  height: 48px;
  left: 0px;
  top: 96px;
  background: #1E323F;
  border-radius: 8px;
}

h3 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #001343;
}
</style>
