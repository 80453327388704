<template>
  <div>
    <InterviewMainCard/>
  </div>
</template>

<script>
import InterviewMainCard from "@/components/InterviewMainCard";
export default {
  name: "InterviewPage",
  components: {InterviewMainCard},
}
</script>

<style scoped>

</style>
