<template>
  <div class="tw-pt-2 ">
    <v-card class="mx-auto max-width='400'">
      <div class="tw-mt-4 tw-mx-2">
        <v-select
            v-model="select"
            :items="getInterviewQuestion"
            item-text="content"
            item-value="id"
            hide-details
            :id="getInterviewQuestion.id"
            :return-object="true"
            label="Question"
            :menu-props="{ maxWidth: '350', maxHeight: '300' }"
            @change="showComment"
            class="comment"
            solo>
          <template v-slot:item='{item}'>
            <div class="mb-n4" v-html='item.content'/>
          </template>
          <template v-slot:selection='{item}'>
            <div v-html='item.content'/>
          </template>
        </v-select>
      </div>
      <h3 class="tw-pt-3 pl-6">Comments</h3>
      <div v-if="!getProgramInterviewComment.length && !get_comment_loading">
        <!--      <QuestionIcon/>-->
        <div class="tw-justify-center tw-mt-24 tw-mb-28">
          <div class="tw-flex tw-justify-center">
            <EmptyStateIcon/>
          </div>
          <h4 class="sub tw-flex tw-justify-center">Your comment will show here</h4>
        </div>

      </div>
      <div v-else-if="get_comment_loading">
        <v-progress-linear
            indeterminate
            color="blue"
        />
      </div>
      <div v-else class="scroll tw-px-6 tw-pb-28">
        <div v-for="(comment,i) in getProgramInterviewComment" :key="i" class="tw-mt-4">
          <h5 class="text">{{ comment.message }}</h5>
          <p>{{ comment.created_by }}, {{ formatDate(comment.date_created) }}</p>
        </div>
      </div>

      <div>
        <v-divider style="position: relative!important;"/>
        <div class="tw-p-3 tw-flex tw-items-center">
          <v-text-field
              v-model="message"
              hide-details
              label="Enter your comment"
              solo

          />
          <div v-if="message" class="tw-ml-2" @click.prevent="sendComment">
            <v-btn icon :loading="loading">
              <SendCommentIcon/>
            </v-btn>
          </div>
        </div>
      </div>

    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {postProgramInterviewComment} from "@/services/api/APIService";
import EmptyStateIcon from "@/components/UIComponents/reusablesIcon/EmptyStateIcon";
import SendCommentIcon from "@/components/UIComponents/reusablesIcon/SendCommentIcon";

export default {
  name: "CommentCard",
  components: {SendCommentIcon, EmptyStateIcon},
  data() {
    return {
      message: '',
      select: '',
      questions: [],
      comments: [],
      commentId: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters('interview', ['getProgramInterviewComment', 'get_comment_loading', 'getInterviewId', 'getQuestionId', 'getInterviewQuestion'])
  },
  created() {
    let payload = {
      site_name: this.$route.query.site_name,
      interview: this.$route.query.interview_id,
      question: this.getInterviewQuestion[0].id
    }
    this.select = this.getInterviewQuestion[0]
    this.commentId = this.getInterviewQuestion[0].id
    this.$store.dispatch('interview/getProgramComment', payload)
  },
  methods: {
    async showComment(item) {
      this.commentId = item.id
      let payload = {
        site_name: this.$route.query.site_name,
        interview: this.$route.query.interview_id,
        question: item.id
      }
      await this.$store.dispatch('interview/getProgramComment', payload)
    },
    async sendComment() {
      this.loading = true
      await postProgramInterviewComment({
            interview: +this.$route.query.interview_id,
            site_name: this.$route.query.site_name,
            message: this.message,
            candidate: this.$route.query.email,
            question: this.commentId,
            // interview_application: "program"
          }
      )
          .then(res => {

            let arrayUpdate = this.getProgramInterviewComment
            arrayUpdate.push(res.data)
            this.$store.commit('interview/setProgramInterviewComment', arrayUpdate)
            this.message = ''
            this.loading = false

          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })

    },
    formatDate(date) {
      if (!date) return null;
      const newDate = date.split('T')[0]
      const [year, month, day] = newDate.split("-");
      return `${month}/${day}/${year}`;
    },
  }

}
</script>

<style scoped lang="scss">
h3 {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #001343;
}

.text {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /*align-items: center;*/
  color: #001343;
}
.comment{
  word-break: break-all;
}

p {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /*display: flex;*/
  /*align-items: center;*/
  color: #9CABB5;
}

.sub {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #001343;

}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: rgba(43, 28, 28, 0.5);
  max-height: 20px !important;
  border-radius: 6px;
}

.v-select__selections {
  //overflow: scroll;
  flex-wrap: nowrap;

}


</style>
