<template>
  <div>
  <v-dialog
      transition="dialog-top-transition"
      width="450"
      v-model="dialog"
      persistent
  >
    <template>
      <v-card class="card">
        <div class="tw-flex title tw-items-center tw-justify-between tw-pt-6 tw-px-6">
          <h3 class="title">End Interview</h3>
          <span class="tw-cursor-pointer" @click="$emit('close')">
             <vue-feather type="x" style="width:19px"/>
          </span>
        </div>

        <div class="tw-my-6">
          <h4 class="card-text md:tw-w-80 tw-ml-6">Are you sure you want to end this interview?</h4>

          <div class="tw-flex tw-mt-4 tw-mb-3 tw-mr-5">
            <v-spacer/>
            <BaseButton :loading="loading" :depressed="true" class="tw-mr-2" width="75px" height="48px" button-text="Yes" @click="goto"/>
            <BaseButton :outlined="true" @click="$emit('close')"  width="75px" height="48px" button-text="No"/>

          </div>
        </div>

      </v-card>
    </template>
  </v-dialog>
    <RecommendationModal  :open="recommend" @close="recommend=false"/>

  </div>
</template>

<script>
import BaseButton from "./UIComponents/BaseButton";
import RecommendationModal from "@/components/RecommendationModal";

export default {
  name: "EndProgramInterviewModal",
  components: {BaseButton, RecommendationModal},
  props:{
    dialog:[Boolean],

  },
  data(){
    return{
      loading:false,
      recommend: false,
    }
  },
  methods:{
    goto(){
      this.recommend = true
      this.$emit('close')

    }
  }
}
</script>

<style scoped>
.card{
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
  padding: 10px;
}
.title{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.card-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #475661;

}
</style>