<template>
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25.001" cy="24.7197" r="24.7197" fill="#001343"/>
    <svg width="45" height="45" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_65726_290880)">
        <path d="M32.1024 17.6922H16.546" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M32.1031 17.689L13.0112 26.8814L16.5467 17.689L13.0112 8.49662L32.1031 17.689Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_65726_290880">
          <rect width="24" height="24" fill="white" transform="translate(17.9609 0.71875) rotate(45)"/>
        </clipPath>
      </defs>
    </svg>

  </svg>

</template>

<script>
export default {
  name: "SendCommentIcon"
}
</script>

<style scoped>

</style>