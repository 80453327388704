<template>
<div>
  <div class="tw-flex tw-justify-between tw-mt-8 tw-mb-5">
    <h2 class="reco">Recommendation comment</h2>
    <div class="case" v-for="(option, i) in getRecommendationComment" :key="i">
      <h2 class="chip tw-justify-items-center tw-px-3 tw-py-1">{{statusText(option.summary)}}</h2>
    </div>
  </div>
  <div class="card tw-px-4 tw-pt-5 scroll" v-for="(data,i) in getRecommendationComment" :key="i">
    <h4>{{data.message}}</h4>
  </div>

</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RecommendationComment",
  data(){
    return{
      loading: false,
      summary:'',
      options:[],
      message:''
    }
  },
  computed:{
    ...mapGetters('interview',['getRecommendationComment']),

  },
  created(){
    let payload={
      site_name: this.$route.query.site_name,
      email: this.$route.query.email,
      interview: this.$route.query.interview_id
    }
    this.$store.dispatch('interview/getProgramRecommendation', payload)
  },
  methods:{
    statusText(name) {
      if(!name)return
      return name.replace(/_/g,' ').replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase());
    },
  }


}
</script>

<style scoped>
.reco{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1em;
  color: #4F4F4F;
}
.chip{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
   display: flex;
  align-items: center;
  color: #FFFFFF;
}
h4{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #001343
}
.case{
  background: #0C8B99;
  border-radius: 16px;
}
.card{
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  min-height: 399px;
}
.scroll {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

</style>